<template>
  <div
    :class="[
      'wayfinder',
      {
        'wayfinder_desktop': isDesktop && !isMobile,
        'wayfinder_mobile': isMobile,
        'wayfinder_wihtout-transition': !useTransition
      }
    ]"
  >
    <loader v-if="viewState.loading === true" :class="[`wayfinder-loader_${ this.$globalTheme }`]" />
    <div class="wayfinder--error-message" v-else-if="viewState.error">
      {{ $l10n(viewState.error) }}
    </div>
    <template v-else>
      <template v-if="currentTheme === 'contrast-dark' || currentTheme ==='contrast-light'">
        <layout-content-contrast :current-layout="currentLayout" :key="'contrast' + loadedDictionary"/>
      </template>
      <template v-else>
        <wayfinder-content :key="'content-' + loadedDictionary" />
        <wayfinder-sidebar :key="'sidebar-' + loadedDictionary" />
      </template>
    </template>

    <focus-frame />
  </div>
</template>

<style src="./components/core/common.less" lang="less"></style>

<script>
  import { mapState, mapActions } from "vuex";
  import { loadLocalizationDictionary } from "../dashboard/lib/l10n.js";
  import { getSearch } from "./lib/utils/url-query.js";

  import Loader from "./components/loader/loader.vue";
  import WayfinderContent from "./components/core/layout-content.vue";
  import WayfinderSidebar from "./components/core/layout-sidebar.vue";
  import FocusFrame from "./components/focus-frame/focus-frame.vue";
  import LayoutContentContrast from "./components/core/layout-content-contrast/layout-content-contrast.vue";

  const urlOptions = getSearch();
  export default {
    name: "wayfinder-layout",
    watch: {
      async selectedLocale(newLocale) {
        await loadLocalizationDictionary(newLocale.toLowerCase());
        this._setLoadedDictionary(newLocale);
        this._addAttributeToHTML(newLocale);
      }
    },
    computed: {
      ...mapState({
        viewState: state => state.view,
        useTransition: state => state.useTransition,
        loadedDictionary: state => state.loadedDictionary,
        legendFilter: state => state.legendFilter,
        theme: state => state.theme
      }),
      currentTheme() {
        if (!this.viewState.view || !this.viewState.view.theme) {
          return this.$globalTheme;
        }

        if (this.viewState.view.theme === "default") {
          return this.$globalTheme;
        }

        return this.viewState.view.theme;
      },
      view() {
        return this.$store.getters.filteredView;
      },
      selectedLocale() {
        return this.$store.getters.selectedLocale;
      }
    },
    data() {
      return {
        isDesktop: "desktop" in urlOptions,
        isMobile: this._isMobileCheck(),
        currentLayout: ""
      };
    },
    methods: {
      ...mapActions({
        fetchView: "view/fetchView",
        _setScreenModificator: "setScreenModificator",
        _showSidebar: "showSidebar",
        _hideSidebar: "hideSidebar",
        _hideKeyboard: "hideKeyboard",
        _resetKeyboardPosition: "resetKeyboardPosition",
        _setLegendFilter: "setLegendFilter",
        _selectLocale: "selectLocale",
        _setLoadedDictionary: "setLoadedDictionary",
        _resetViewState: "view/resetViewState",
        _setTheme: "setTheme"
      }),
      setCurrentLayout(){
        if (this.viewState.view.layout === "default")
          return this.$globalLayout;

        return this.viewState.view.layout;
      },
      _addAttributeToHTML(value){
        let htmlElement = document.querySelector("html");
        htmlElement.setAttribute("lang", value);
      },
      _isMobileCheck() {
        if (this.$isTablet && this.currentTheme === "classic") {
          return parseInt(window.getComputedStyle(document.body).width, 10) < 1370;
        }

        return parseInt(window.getComputedStyle(document.body).width, 10) < 950;
      },
      _updateIsMobile() {
        this.isMobile = this._isMobileCheck();
        this._setScreenModificator({
          isMobile: this.isMobile,
          isDesktop: this.isDesktop && !this.isMobile
        });

        if (this.isMobile && this.legendFilter === "") {
          this._hideSidebar();
        }
      }
    },
    created() {
      this._updateIsMobile = this._updateIsMobile.bind(this);
    },
    async mounted() {
      document.title = "easyWay by Inlead";

      await this.fetchView({ viewId: urlOptions.view });
      this.currentLayout = this.setCurrentLayout();
      this._updateIsMobile();

      if (this.$isDesktopDevice)
        window.addEventListener("resize", this._updateIsMobile);

      if (this.theme === "")
        await this._setTheme(this.currentTheme);
    },
    beforeDestroy() {
      if (this.$isDesktopDevice)
        window.removeEventListener("resize", this._updateIsMobile);
    },
    screenPending() {
      this._showSidebar();
      this._hideKeyboard();
      this._setLegendFilter("");
      this._selectLocale("");
      this._resetViewState();
      this._setTheme(this.currentTheme);
    },
    components: {
      LayoutContentContrast,
      Loader,
      WayfinderContent,
      WayfinderSidebar,
      FocusFrame
    }
  };
</script>

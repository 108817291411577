import "../static/styles/less/base-icons.less";
import "../static/vendor/fontawesome-pro-6.1.1-web/css/all.min.css";

import Vue from "vue";
import Vuex from "vuex";
import lodash from "lodash";
import axios from "axios";
import { UAParser } from "ua-parser-js";
import "../lib/vendor/device-detector";
import "../dashboard/core/image-onready.directive.js";
import asyncTimeout from "../dashboard/lib/async-timeout.js";

import Config from "./lib/config.js";
import CanvasOrientation from "./lib/canvas-orientation.js";
import { getSearch } from "./lib/utils/url-query.js";
import ScreenActivity from "./lib/screen-activity.js";
import FrontendStore from "./store/index.js";
import { localize } from "../dashboard/lib/l10n.js";

import CanvasControl from "./components/canvas-control/canvas-control.vue";
import FrontendApp from "./main.vue";

(async function() {
  const { device } = UAParser(window.navigator.userAgent);
  const urlOptions = getSearch();
  const config = new Config({
    debug: {
      enable: "debug" in urlOptions,
      canvas: {
        move: true,
        scale: true,
        orientation: {
          width: 1920,
          height: 1080
        }
      }
    }
  });

  let pendingTime = "120s";
  let accessibility = {
    disableZoom: false,
    enableLegendScrollBar: false
  };
  let theme = "classic";
  let layout = "default";
  let skin = "";

  try {
    const response = await axios.get("/preferences");
    pendingTime = lodash.get(response, "data.pendingTime.pendingTime") || pendingTime;
    accessibility = lodash.get(response, "data.accessibility");
    if (accessibility.goBackButton.enable === false)
      accessibility.goBackButton.platforms = [];

    theme = lodash.get(response, "data.design.theme") || theme;
    layout = lodash.get(response, "data.design.layout") || layout;
    skin = lodash.get(response, "data.skin") || skin;
    document.body.setAttribute("data-skin", skin);
  } catch (pendingTimeRequestError) {
    console.error("Can't fetch peding time.", pendingTimeRequestError);
  }

  const screenActivity = new ScreenActivity({
    disabled: "desktop" in urlOptions,
    pendingTime: pendingTime
  });

  let scaleMode = "";
  let scaleTarget = null;

  if (config.get("debug.enable") === true) {
    scaleMode = "manual";
    scaleTarget = config.get("debug.canvas.orientation");
  } else if ("fit" in urlOptions) {
    scaleMode = "fit";
    scaleTarget = { width: 1920, height: 1080 };
  }

  const canvasOrientation = new CanvasOrientation({
    el: document.querySelector("body"),
    mode: scaleMode,
    scaleTarget: scaleTarget
  });

  /* Make vuex available throughout project. */
  Vue.use(Vuex);

  Vue.mixin({
    beforeCreate() {
      /* Helper for development. */
      this.log = console.log;

      /* The stub for localization method. */
      this.$l10n = localize;

      this.$screenActivity = screenActivity;

      /* Define the global accessibility options. */
      this.$accessibility = accessibility;

      /* Define the global theme options. */
      this.$globalTheme = theme;

      /* Define the global layout options. */
      this.$globalLayout = layout;

      /* Define canvas orientation. */
      this.$easyscreenCanvasOrientation = canvasOrientation;

      /* Define tablet device. "Device" - is importing from lib/vendor/device-detector */
      this.$isTablet = device.model === "iPad" || window.Device.isTablet;

      /* Define desktop device. "Device" - is importing from lib/vendor/device-detector */
      this.$isDesktopDevice = window.Device.isDesktop;

      /* Returns the label\title with selected or default locale. */
      this.$withLocale = (label) => {
        if (!label)
          return label;

        let locale = this.$store.getters.selectedLocale;
        if (!(locale in label)) {
          console.warn(`Selected locale "${ locale }" not found for `, label);
        }

        return label[locale] || "";
      };

      this.$skin = skin;
    },
    async mounted() {
      if (lodash.isFunction(this.$options.screenRunning)) {
        await asyncTimeout(500);
        if (this.$screenActivity.state === "running" && this._isDestroyed === false) {
          /*
           * The timeout are required for correct element css calculations on
           * first load of widget to wait when the css styles will be applied.
           */
          this.$options.screenRunning.call(this);
        }

        this.$once("hook:beforeDestroy", this.$screenActivity.on("running", () => {
          this.$options.screenRunning.call(this);
        }));
      }

      if (lodash.isFunction(this.$options.screenPending)) {
        this.$once("hook:beforeDestroy", this.$screenActivity.on("pending", () => {
          this.$options.screenPending.call(this);
        }));
      }
    }
  });

  new (Vue.extend(FrontendApp))({
    store: new Vuex.Store(FrontendStore)
  }).$mount("#app");

  if (config.get("debug.enable") === true) {
    const canvasControl = new (Vue.extend(CanvasControl))({
      propsData: {
        canvasRotate: config.get("debug.enable") === true,
        canvasMove: config.get("debug.canvas.move") === true,
        canvasScale: config.get("debug.canvas.scale") === true
      }
    });
    const canvasControlContainer = document.createElement("div");
    document.body.appendChild(canvasControlContainer);
    /* Move the canvas control container out of body to prevent the scale and translation effects. */
    document.querySelector("html").appendChild(canvasControlContainer);
    canvasControl.$mount(canvasControlContainer);
  }

  function postmessageScreenActivity(source, id) {
    const action = () => {
      source.postMessage({ message: "action:postmessage-screen-activity", id }, "*");
    };

    window.addEventListener("mouseup", action);
    window.addEventListener("mousedown", action);
    window.addEventListener("touchstart", action);
    window.addEventListener("touchend", action);
    window.addEventListener("keydown", action);
    window.addEventListener("blur", () => {
      source.postMessage({ message: "close:postmessage-screen-activity", id }, "*");
    });
  }

  function postmessageScreenActivitySubscribtion(event) {
    if (event.data.message === "enable:postmessage-screen-activity") {
      event.source.postMessage({ message: "enabled:postmessage-screen-activity", id: event.data.id }, "*");
      postmessageScreenActivity(event.source, event.data.id);

      window.removeEventListener("message", postmessageScreenActivitySubscribtion);
    }
  }
  window.addEventListener("message", postmessageScreenActivitySubscribtion);
})().catch(error => {
  console.error(error);
});
